import dynamic from "next/dynamic";
import Link from "next/link";
import { useRouter } from "next/router";
import { useMemo, useState } from "react";
import { isNavLinkActive } from "utils/clientUtil";
import { getPageUrlOrRootUrl } from "utils/util";
import FullScreenHoverNavigationStyle from "./fullScreenHoverNavigationStyle";

const Button = dynamic(() => import("@mui/material/Button"));
const Box = dynamic(() => import("@mui/material/Box"));

/**
 * NOTE: Formatting with prettier seems broken in this file?
 * I added // prettier-ignore to the component
 *
 * @param {Array} props.navigation the navigation object
 * @returns
 */
// prettier-ignore
const FullScreenHoverNavigation = ({ navigation }) => {
  const router = useRouter();
  const [showNavDrawer, setShowNavDrawer] = useState(false);

  // building the 2D array to loop through for hover navigation
  const subLayerArray = useMemo(() => {
    let secondAndThirdLevelPages = [];
    // added optional chaining for locale switch
    navigation?.pages.forEach((layer1Page) => {
      if (layer1Page.subPages.length > 0) {
        // entering subpages from layer 1 page
        layer1Page.subPages.forEach((layer2Page) => {
          // creating array that contains layer 2
          secondAndThirdLevelPages.push([{ ...layer2Page, layer: 2 }]);
          if (layer2Page.subPages.length > 0) {
            //entering subpages from layer 2 page
            layer2Page.subPages.forEach((layer3Page) => {
              // pushing layer 3 pages to previously added array to
              // have layer 2 with all subPages in a single array
              secondAndThirdLevelPages[
                secondAndThirdLevelPages.length - 1
              ].push({ ...layer3Page, layer: 3 });
            });
          }
        });
      }
    });
    return secondAndThirdLevelPages;
  }, [navigation]);

  return (
    <>
      <Box
        className="navigation-content-box"
        onMouseEnter={() => setShowNavDrawer(true)}
        onMouseLeave={() => setShowNavDrawer(false)}
      >
        {navigation && navigation.pages.length > 0
          ? navigation.pages.map((layer1page, index) => {
              // replace the entire ternary below with this one to prevent rendering the root page in the navigation
              // return layer1page.page &&
              //     layer1page.page.url !==
              //       process.env.NEXT_PUBLIC_ROOT_PAGE_URL
              return layer1page.page ? (
                <Link
                  key={layer1page.name + index}
                  href={getPageUrlOrRootUrl(layer1page.page.url)}
                  passHref
                >
                  <Button
                    className={`${
                      isNavLinkActive(router, layer1page) ? "active " : ""
                    }base-level`}
                    key={layer1page.name + index}
                  >
                    {layer1page.name}
                  </Button>
                </Link>
              ) : layer1page.externalUrl ? (
                <Button
                  className="base-level"
                  rel="nofollow noopener noreferrer"
                  target="_blank"
                  href={`${layer1page.externalUrl}`}
                  key={layer1page.name + index}
                >
                  {layer1page.name}
                </Button>
              ) : null;
            })
          : null}
      </Box>
      {subLayerArray.length > 0 ? (
        <div
          className={`${showNavDrawer ? "" : "hidden "}nav-hover-drawer`}
          onMouseEnter={() => setShowNavDrawer(true)}
          onMouseLeave={() => setShowNavDrawer(false)}
        >
          <div className="hover-connector"></div>

          <div className="nav-hover-drawer-content">
            {subLayerArray.map((subNavArray, index) => {
              return (
                <div
                  key={index + "nav-drawer-col"}
                  className="nav-hover-drawer-col"
                >
                  {subNavArray.map((navItem, innerIndex) => {
                    return (
                      <div
                        key={innerIndex + "nav-drawer-nav-item"}
                        className={`${
                          navItem.layer === 2 ? "layer2" : "layer3"
                        } nav-item`}
                      >
                        {navItem.page ? (
                          // add this to the ternary above to prevent rendering root page in the navigation
                          // && navItem.page.url !==
                          //   process.env.NEXT_PUBLIC_ROOT_PAGE_URL
                          <Link href={getPageUrlOrRootUrl(navItem.page.url)} passHref>
                            <a
                              className={`${
                                isNavLinkActive(router, navItem)
                                  ? "active "
                                  : ""
                              }nav-item-drawer-link`}
                              onClick={() => setShowNavDrawer(false)}
                            >
                              {navItem.name}
                            </a>
                          </Link>
                        ) : navItem.externalUrl ? (
                          <a
                            className={`nav-item-drawer-link external-link`}
                            rel="nofollow noopener noreferrer"
                            target="_blank"
                            href={`${navItem.externalUrl}`}
                          >
                            {navItem.name}
                          </a>
                        ) : null}
                      </div>
                    );
                  })}
                </div>
              );
            })}
          </div>
        </div>
      ) : null}
      <FullScreenHoverNavigationStyle />
    </>
  );
};
export default FullScreenHoverNavigation;
