import { globalSettings } from "services/globalSettings/globalSettingsService";
export default function FullScreenHoverNavigationStyle() {
  return (
    <style jsx global>{`
      .nav-hover-drawer.hidden {
        visibility: hidden;
        opacity: 0;
      }
      .nav-hover-drawer {
        transition: visibility 0.2s linear, opacity 0.2s linear;
        opacity: 1;
        visibility: visible;

        position: absolute;
        display: block;
        width: 100%;
        padding-left: ${globalSettings.navigation.spaceX}px;
        padding-right: ${globalSettings.navigation.spaceX}px;
        bottom: 0;
        transform: translateY(100%);
        left: 0;
        z-index: 3;
        box-shadow: 0px 1px 5px -3px black;
        background-color: ${globalSettings.navigation.hoverMenuBackgroundColor};
        .nav-hover-drawer-content {
          margin: auto;
          padding: ${`${globalSettings.navigation.paddingDropdownY}px ${globalSettings.navigation.paddingDropdownX}px`};
          max-width: ${globalSettings.navigation.centerNavigationWithImage
            ? globalSettings.navigation.navigationCenteredMaxWidth
            : "100%"};
          display: grid;
          grid-template-columns: repeat(auto-fill, 300px);
          justify-content: space-between;
          .nav-hover-drawer-col {
            width: 300px;
            padding: 0 0 15px 0;
          }
        }
        .hover-connector {
          display: block;
          position: absolute;
          top: 0;
          left: 0;
          transform: translateY(-100%);
          width: 100%;
          height: ${globalSettings.navigation.spaceY}px;
          background-color: rgba(0, 0, 0, 0);
        }
      }
      .nav-hover-drawer {
        .nav-item {
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          padding: ${`${globalSettings.navigation.navbarElementPaddingY}px ${globalSettings.navigation.navbarElementPaddingX}px`};
          color: ${globalSettings.navigation.notBaseLayerElementFontColor};
          &.layer2 {
            .nav-item-drawer-link {
              font-weight: ${globalSettings.navigation.fontWeightLayer2 || 400};
            }
          }
          &.layer3 {
            .nav-item-drawer-link {
              font-weight: ${globalSettings.navigation.fontWeightLayer3 || 400};
            }
          }

          .nav-item-drawer-link {
            font-size: ${globalSettings.navigation.fontSize}px;

            // to increase border size add this
            // padding-left: .1rem;
            // padding-right: .1rem;
            color: ${globalSettings.navigation.notBaseLayerElementFontColor};
            text-decoration: none;
            &:hover {
              text-decoration: none;
              border-bottom: solid 2px
                ${globalSettings.navigation.hoverElementBackgroundColor};
            }
            &.active {
              text-decoration: none;
              border-bottom: solid 2px
                ${globalSettings.navigation.activeElementBackgroundColor};
            }
          }
        }
      }
    `}</style>
  );
}
